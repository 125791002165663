export const environment = {
  name: 'alpha',
  isProduction: true,
  apiUrl: 'https://alpha-db.juntotalk.com',
  webAppUrl: 'https://alpha.juntotalk.com',
  stripePublishableKey:
    'pk_test_51Ms6PALVlBsx0ZUqAqFiyyVWosNzjbk4jhakM9xcBwrQErTGJMHbM1QRgaii6vaYCWCIVTeKEh2c4azgIBVznZId00DswZZhnF',
  googleAnalytics: 'TODO',
  stripeClientId: 'TODO',
};
