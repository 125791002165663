import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AccountService } from './services/account.service';
import { ErrorService } from './services/error.service';
import { NgswHelperService } from './services/ngsw-helper.service';
import { TranslationService } from './services/translation.service';
import { TypeAccessorService } from './services/type-accessor.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Component({
  selector: 'app-root',
  standalone: false,
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  private isWatchingPushNotifications: boolean = false;

  constructor(
    private ngswHelperService: NgswHelperService,
    public accountService: AccountService,
    public tas: TypeAccessorService,
    public tls: TranslationService,
    private errorService: ErrorService,
    private router: Router,
    private zone: NgZone
  ) {
    this.ngswHelperService.init();
    this.setupDeepLinks();
  }

  get envName(): string {
    return environment.name;
  }

  ngOnInit() {
    const loadingLogo = document.querySelector('.loading-logo');
    if (loadingLogo) {
      loadingLogo.remove();
    }

    this.accountService.account$.subscribe(async account => {
      if (account) {
        try {
          await this.setupPushNotifications();
        } catch (err) {
          await this.errorService.displayError(`Error setting up push notifications`, err, true);
        }
      }
    });
  }

  private async setupPushNotifications(): Promise<void> {
    if (!isPlatform('hybrid') || this.isWatchingPushNotifications) {
      return;
    }

    PushNotifications.requestPermissions();
    PushNotifications.register();

    PushNotifications.addListener('registration', async token => {
      try {
        await this.accountService.addDeviceToken(token.value);
        console.log('Device token added successfully');
      } catch (err) {
        console.error('Error adding device token', err);
        const errorMessage = this.errorService.getErrorMessage(err);
        if (errorMessage.includes('already exists')) {
          return;
        }
      }
    });

    PushNotifications.addListener('registrationError', error => {
      this.errorService.displayError(`Error registering for push notifications`, error, true);
    });

    //   PushNotifications.addListener(
    //     'pushNotificationReceived',
    //     async (notification) => {
    //       console.log('Push received: ' + JSON.stringify(notification));
    //       const toast = await this.toastCtrl.create({
    //         message: notification.title,
    //         duration: TOAST_DURATION,
    //         buttons: [
    //           {
    //             text: 'View',
    //             handler: () => {
    //               this.router.navigateByUrl(notification.data.path);
    //             },
    //           },
    //         ],
    //       });
    //       await toast.present();
    //     }
    //   );

    PushNotifications.addListener('pushNotificationActionPerformed', action => {
      console.log('Push action performed: ' + JSON.stringify(action));
      if (action.notification?.data?.path) {
        this.router.navigateByUrl(action.notification.data.path);
      }
    });

    this.isWatchingPushNotifications = true;
  }

  private setupDeepLinks(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        console.log('deep link', event.url);
        const slug = event.url.split('.app').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }
}
