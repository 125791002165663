import { Account } from '../models/account';

export const isAccountPaid = (account: Account | undefined) => {
  if (account && account.subscriptionEndsAt && new Date(account.subscriptionEndsAt) > new Date()) {
    return true;
  }

  if (account && account.freeUntil && new Date(account.freeUntil) > new Date()) {
    return true;
  }

  return false;
};
