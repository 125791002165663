// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-menu {
  border-right: 1px solid var(--ion-color-medium);
}

ion-list {
  --ion-background-color: transparent;
}

ion-item {
  --color: var(--ion-color-light);
  --background: transparent;
}
ion-item ion-icon {
  color: var(--ion-color-light);
}

ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}
ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

img {
  width: 200px;
  display: block;
  margin: auto;
}

.env-name {
  background: rgba(0, 0, 0, 0.272);
  color: white;
  padding: 5px;
  font-size: 10px;
  line-height: 10px;
  position: absolute;
  bottom: var(--ion-safe-area-bottom);
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;AACF;;AAEA;EACE,mCAAA;AACF;;AAEA;EACE,+BAAA;EACA,yBAAA;AACF;AAAE;EACE,6BAAA;AAEJ;;AAEA;EACE,sDAAA;AACF;AACE;EACE,+BAAA;AACJ;;AAGA;EACE,YAAA;EACA,cAAA;EACA,YAAA;AAAF;;AAGA;EACE,gCAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mCAAA;EACA,QAAA;AAAF","sourcesContent":["ion-menu {\n  border-right: 1px solid var(--ion-color-medium);\n}\n\nion-list {\n  --ion-background-color: transparent;\n}\n\nion-item {\n  --color: var(--ion-color-light);\n  --background: transparent;\n  ion-icon {\n    color: var(--ion-color-light);\n  }\n}\n\nion-item.selected {\n  --background: rgba(var(--ion-color-primary-rgb), 0.14);\n\n  ion-icon {\n    color: var(--ion-color-primary);\n  }\n}\n\nimg {\n  width: 200px;\n  display: block;\n  margin: auto;\n}\n\n.env-name {\n  background: rgba(0, 0, 0, 0.272);\n  color: white;\n  padding: 5px;\n  font-size: 10px;\n  line-height: 10px;\n  position: absolute;\n  bottom: var(--ion-safe-area-bottom);\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
