import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { Role } from './models/role';

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./pages/reset-password/reset-password.page').then(m => m.ResetPasswordPage),
  },
  {
    path: 'verify-email',
    loadComponent: () => import('./pages/verify-email/verify-email.page').then(m => m.VerifyEmailPage),
  },
  {
    path: 'verify-phone',
    loadComponent: () => import('./pages/verify-phone/verify-phone.page').then(m => m.VerifyPhonePage),
  },
  {
    path: 'account-email-preference',
    loadComponent: () =>
      import('./pages/account-email-preference/account-email-preference.page').then(
        m => m.AccountEmailPreferencePage
      ),
  },
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadComponent: () => import('./pages/account/account.page').then(m => m.AccountPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'welcome',
    loadComponent: () => import('./pages/welcome/welcome.page').then(m => m.WelcomePage),
    canActivate: [AuthGuard],
  },
  {
    path: 'recordings',
    loadComponent: () => import('./pages/recordings/recordings.page').then(m => m.RecordingsPage),
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'waitlist-entries',
    loadComponent: () =>
      import('./pages/waitlist-entries/waitlist-entries.page').then(m => m.WaitlistEntriesPage),
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'call-history',
    loadComponent: () => import('./pages/call-history/call-history.page').then(m => m.CallHistoryPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'subscription',
    loadComponent: () => import('./pages/subscription/subscription.page').then(m => m.SubscriptionPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'notification-settings',
    loadComponent: () =>
      import('./pages/notification-settings/notification-settings.page').then(
        m => m.NotificationSettingsPage
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'prompts',
    loadComponent: () => import('./pages/prompts/prompts.page').then(m => m.PromptsPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'default-prompts',
    loadComponent: () => import('./pages/prompts/prompts.page').then(m => m.PromptsPage),
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'transactions',
    loadComponent: () => import('./pages/transactions/transactions.page').then(m => m.TransactionsPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'waitlist',
    loadComponent: () => import('./pages/waitlist/waitlist.page').then(m => m.WaitlistPage),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'accounts',
    loadComponent: () => import('./pages/accounts/accounts.page').then(m => m.AccountsPage),
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'account-admin/:id',
    loadComponent: () => import('./pages/account-admin/account-admin.page').then(m => m.AccountAdminPage),
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'terms',
    loadComponent: () => import('./pages/terms/terms.page').then(m => m.TermsPage),
  },
  {
    path: 'privacy',
    loadComponent: () => import('./pages/privacy/privacy.page').then(m => m.PrivacyPage),
  },
  {
    path: 'global-config',
    loadChildren: () =>
      import('./pages/global-config/global-config.module').then(m => m.GlobalConfigPageModule),
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'mobile-app',
    loadComponent: () => import('./pages/mobile-app/mobile-app.page').then(m => m.MobileAppPage),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
