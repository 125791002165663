import { Translation } from '../models/translation';

// map from language code to translation object
export const translationMap: { [key: string]: Translation } = {
  en: {
    en: 'English',
    es: 'Spanish',
    pt: 'Portuguese',
    fr: 'French',
    de: 'German',
    ja: 'Japanese',
    it: 'Italian',
    ko: 'Korean',
    zh: 'Chinese',
    hi: 'Hindi',
    ru: 'Russian',
    ar: 'Arabic',
    email: 'Email',
    displayName: 'Display Name',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    acceptTerms: 'I accept the terms and conditions',
    iAccept: 'I accept',
    terms: 'terms',
    privacy: 'privacy',
    copyright: 'copyright',
    createAccount: 'Create Account',
    login: 'Login',
    returnToLogin: 'Return to Login',
    forgotPassword: 'Forgot Password',
    passwordsDoNotMatch: 'Passwords do not match',
    accountCreatedSuccessfully: 'Account created successfully',
    loggedInSuccessfully: 'Logged in successfully',
    pleaseEnterYourEmailToReceiveYourPasswordResetLink:
      'Please enter your email to receive your password reset link',
    passwordResetLinkSentToYourEmailIfItExistsInOurSystem:
      'Password reset link sent to your email if it exists in our system',
    account: 'Account',
    save: 'Save',
    nativeLanguage: 'Native Language',
    learningLanguage: 'Learning Language',
    selectOne: 'Select One',
    createdOn: 'Created On',
    updatedOn: 'Updated On',
    deleteAccount: 'Delete Account',
    logout: 'Logout',
    accountUpdatedSuccessfully: 'Account updated successfully',
    errorUpdatingAccount: 'Error updating account',
    deleteAccountConfirmation: 'Deleting your account cannot be undone. Please type "DELETE" to confirm.',
    deleteAccountConfirmationSingle: 'DELETE',
    deleteAccountConfirmationError: 'Invalid entry, please type "DELETE"',
    deleteAccountError: 'Error deleting account',
    cancel: 'Cancel',
    confirm: 'Confirm',
    logoutError: 'Error logging out',
    native: 'Native',
    learning: 'Learning',
    searchCall: 'Search Call',
    cancelSearch: 'Cancel Search',
    disconnect: 'Disconnect',
    callWillBeRecorded: 'Call will be recorded',
    cancellingSearch: 'Cancelling search...',
    searchCancelled: 'Search cancelled',
    searchCancelError: 'Error cancelling search',
    searching: 'Searching...',
    connecting: 'Connecting...',
    connected: 'Connected',
    noMatchesFoundPleaseTryAgainLater: 'No matches found, please try again later',
    failedToConnect: 'Failed to connect',
    disconnected: 'Disconnected',
    failedToDisconnect: 'Failed to disconnect',
    initializingClient: 'Initializing client...',
    failedToInitializeClient: 'Failed to initialize client',
    callRejected: 'Call rejected',
    errorRejectingCall: 'Error rejecting call',
    callAccepted: 'Call accepted',
    errorAcceptingCall: 'Error accepting call',
    errorStartingCallRecording: 'Error starting call recording',
    recordings: 'Recordings',
    adminViewOfAllRecordings: 'Admin view of all recordings',
    yourBrowserDoesNotSupportTheAudioElement: 'Your browser does not support the audio element',
    endOfList: 'End of list',
    resetPassword: 'Reset Password',
    invalidPasswordResetLink: 'Invalid password reset link',
    passwordResetSuccessfully: 'Password reset successfully',
    passwordResetFailed: 'Password reset failed',
    yourSessionIsExpiringWouldYouLikeToContinue: 'Your session is expiring, would you like to continue?',
    continueSession: 'Continue Session',
    welcome: 'Welcome',
    continue: 'Continue',
    failedToUpdateAccount: 'Failed to update account',
    errorRequiredField: 'Error - required field',
    errorMinValueField: 'Error - minimum value',
    errorMaxValueField: 'Error - maximum value',
    errorPhoneNumberField: 'Error - invalid phone number',
    errorEmailField: 'Error - invalid email',
    newVersionAvailable: 'New version available',
    reload: 'Reload',
    errorLoadingCallerProfile: 'Error loading caller profile',
    icon: 'Icon',
    chooseIcon: 'Choose Icon',
    mute: 'Mute',
    unmute: 'Unmute',
    muteError: 'Error muting',
    rateYourProficiency: 'Rate your proficiency',
    pleaseRateYourProficiencyFor: 'Please rate your proficiency for',
    submit: 'Submit',
    errorLoadingMyMostRecentRating: 'Error loading my most recent rating',
    callSummary: 'Call Summary',
    pleaseRateYourPartnersProficiencyFor: 'Please rate your partner’s proficiency for',
    pleaseRateTheCallQuality: 'Please rate the call quality',
    errorSubmittingRating: 'Error submitting rating',
    comments: 'Comments',
    callDuration: 'Call Duration',
    callHistory: 'Call History',
    errorLoadingLanguageTimes: 'Error loading language times',
    languageCallTimes: 'Language Call Times',
    selectAudioOutput: 'Select Audio Output',
    selectAudioOutputError: 'Error selecting audio output',
    selectAudioInput: 'Select Audio Input',
    selectAudioInputError: 'Error selecting audio input',
    audioPermissionRequired: 'Audio permission required',
    noAudioElementFound: 'No audio element found',
    bluetoothError: 'Bluetooth error',
    subscription: 'Subscription',
    manageSubscription: 'Manage Subscription',
    inactive: 'Inactive',
    active: 'Active',
    unknown: 'Unknown',
    monthly: 'Monthly',
    yearly: 'Yearly',
    month: 'Month',
    year: 'Year',
    unlimitedCalls: 'Unlimited calls',
    dayFreeTrial: 'day free trial',
    verifyEmail: 'Verify Email',
    verifyPhone: 'Verify Phone',
    sendEmail: 'Send Email',
    refresh: 'Refresh',
    pleaseProvidePhoneForAccount: 'Please provide a phone number for your account',
    phoneNumber: 'Phone Number',
    sendCode: 'Send Code',
    code: 'Code',
    verifyCode: 'Verify Code',
    emailAlreadyVerifiedTitle: 'Email Already Verified',
    emailAlreadyVerifiedMessage: 'Your email has already been verified',
    emailVerificationRequiredTitle: 'Email Verification Required',
    emailVerificationRequiredMessage: 'Email verification is required for your account',
    emailVerificationError: 'Email verification error',
    verificationSuccessfulTitle: 'Verification Successful',
    verificationSuccessfulMessage: 'Redirecting...',
    verificationFailedTitle: 'Verification Failed',
    errorSendingEmail: 'Error sending email',
    verificationEmailSent: 'Verification email sent',
    codeSent: 'Code Sent',
    errorSendingCode: 'Error sending code',
    codeVerificationSuccessful: 'Code verification successful. Redirecting...',
    errorVerifyingCode: 'Error verifying code',
    waitlist: 'Waitlist',
    enterYourEmailToJoinTheWaitlist: 'Enter your email to join the waitlist!',
    youHaveJoinedTheWaitlistSuccessfully: 'You have joined the waitlist successfully!',
    errorJoiningWaitlist: 'Error joining waitlist',
    platform: 'Platform',
    errorLoadingRecordings: 'Error loading recordings',
    noRecordingsFound: 'No recordings found',
    errorDownloadingReport: 'Error downloading report',
    errorLoadingWaitlist: 'Error loading waitlist',
    waitlistEntries: 'Waitlist Entries',
    ios: 'iOS',
    android: 'Android',
    web: 'Web',
    reportUser: 'Report User',
    none: 'None',
    explicitLanguage: 'Explicit Language',
    sexualContent: 'Sexual Content',
    harassment: 'Harassment',
    robot: 'Robot',
    other: 'Other',
    pleaseLoginToContinue: 'Please login to continue',
    home: 'Home',
    notificationSettings: 'Notification Settings',
    youHaveOptedOutFromEmails: 'You have opted out from emails',
    change: 'Change',
    newCallSearch: 'New Call Search',
    push: 'Push',
    sms: 'SMS',
    transactionReceipt: 'Transaction Receipt',
    unusualLogin: 'Unusual Login',
    errorLoadingNotificationSettings: 'Error loading notification settings',
    notificationSettingsSavedSuccessfully: 'Notification settings saved successfully',
    errorSavingNotificationSettings: 'Error saving notification settings',
    accountEmailPreference: 'Account Email Preference',
    manageYourAccountEmailPreferences: 'Manage your account email preferences',
    unsubscribe: 'Unsubscribe',
    resubscribe: 'Resubscribe',
    successfullyChangedEmailPreferences: 'Successfully changed email preferences',
    errorChangingEmailPreferences: 'Error changing email preferences',
    enterCommentsHere: 'Enter comments here',
    input: 'Input',
    output: 'Output',
    uploadImage: 'Upload Image',
    removeImage: 'Remove Image',
    image: 'Image',
    saving: 'Saving',
    selectImage: 'Select Image',
    prompt: 'Prompt',
    prompts: 'Prompts',
    defaultPrompts: 'Default Prompts',
    errorSavingPrompts: 'Error saving prompts',
    errorSavingDefaultPrompts: 'Error saving default prompts',
    enabled: 'Enabled',
    groupName: 'Group Name',
    enterGroupName: 'Enter group name',
    enterConversationPrompt: 'Enter conversation prompt',
    promptsSavedSuccessfully: 'Prompts saved successfully',
    defaultPromptsSavedSuccessfully: 'Default prompts saved successfully',
    next: 'Next',
    yourTurnToPrompt: 'Your turn to prompt',
    theirTurnToPrompt: 'Their turn to prompt',
    confirmDelete: 'Confirm Delete',
    areYouSureYouWantToDeleteThisPromptList: 'Are you sure you want to delete this prompt list?',
    delete: 'Delete',
    areYouSureYouWantToDeleteThisPrompt: 'Are you sure you want to delete this prompt?',
    frequency: 'Frequency',
    xAtATime: 'X at a time',
    enterFrequency: 'Enter frequency',
    promptListCreated: 'Prompt list created',
    errorUpdatingPromptMetadata: 'Error updating prompt metadata',
    paymentMethod: 'Payment Method',
    cardOnFile: 'Card on File',
    updatePaymentMethod: 'Update Payment Method',
    nameOnCard: 'Name on Card',
    noPaymentMethod: 'No Payment Method',
    pleaseAddAPaymentMethod: 'Please add a payment method',
    errorLoadingPaymentMethods: 'Error loading payment methods',
    paymentMethodUpdatedSuccessfully: 'Payment method updated successfully',
    errorUpdatingPaymentMethod: 'Error updating payment method',
    downgrade: 'Downgrade',
    subscribe: 'Subscribe',
    free: 'Free',
    currentSubscription: 'Current Subscription',
    oneFifteenMinuteCallPerDay: 'One 15 minute call per day',
    errorUpdatingSubscription: 'Error updating subscription',
    errorGettingPrices: 'Error getting prices',
    periodEndsAt: 'Period ends at',
    subscriptionUpdatedSuccessfully: 'Subscription updated successfully',
    transactions: 'Transactions',
    viewTransactions: 'View Transactions',
    errorLoadingTransactions: 'Error loading transactions',
    paymentSucceeded: 'Payment Succeeded',
    paymentFailed: 'Payment Failed',
    paymentRefunded: 'Payment Refunded',
    paidUntil: 'Paid Until',
    subscribeToCreatePrompts: 'Subscribe to create prompts',
    neitherUserSubscribedPromptsUnavailable: 'Neither user subscribed, prompts unavailable',
    mobileApp: 'Mobile App',
    mobileAppDescription:
      'JuntoTalk offers progressive web app support. You can add app.juntotalk.com to your home screen and use it as a native app.',
    mobileAppIOSInstructions: 'To do this on iOS, tap the share button, then tap "Add to Home Screen."',
    mobileAppAndroidInstructions:
      'To do this on Android, tap the menu button in Chrome, then tap "Add to Home Screen."',
    subscribeToAccessCallRecordings: 'Subscribe to access call recordings',
    callRecordings: 'Call Recordings',
    website: 'Website',
    otherLearningLanguage: 'Other Learning Language',
    otherNativeLanguage: 'Other Native Language',
    acceptCallsFrom: 'Accept Calls From',
    selectOneOrMore: 'Select One or More',
    acceptCallsFromMultipleLanguages: 'Accept calls from multiple languages',
    moreInfo: 'More Info',
    accounts: 'Accounts',
    noAccountFound: 'No account found',
    noAccountsFound: 'No accounts found',
    go: 'Go',
    freeUntil: 'Free until',
    done: 'Done',
    errorLoadingAccount: 'Error loading account',
    errorLoadingAccounts: 'Error loading accounts',
    search: 'Search',
    ai: 'AI',
    failedToChatWithAi: 'Failed to chat with AI',
    callWillBeAnsweredByAI: 'If no one is available, the call will be answered by AI',
    hasPictures: 'Has Pictures',
    errorRemovingImage: 'Error removing image',
    viewImage: 'View Image',
    errorSendingMessage: 'Error sending message',
    pleaseOnlyUploadPngOrJpgFiles: 'Please only upload PNG or JPG files',
  },
  es: {
    en: 'Inglés',
    es: 'Español',
    pt: 'Portugués',
    fr: 'Francés',
    de: 'Alemán',
    ja: 'Japonés',
    it: 'Italiano',
    ko: 'Coreano',
    zh: 'Chino',
    hi: 'Hindi',
    ru: 'Ruso',
    ar: 'Árabe',
    email: 'Correo Electrónico',
    displayName: 'Nombre de Usuario',
    password: 'Contraseña',
    confirmPassword: 'Confirmar Contraseña',
    acceptTerms: 'Acepto los términos y condiciones',
    iAccept: 'Acepto',
    terms: 'términos',
    privacy: 'privacidad',
    copyright: 'derechos de autor',
    createAccount: 'Crear Cuenta',
    login: 'Iniciar Sesión',
    returnToLogin: 'Volver al Inicio de Sesión',
    forgotPassword: 'Olvidé mi Contraseña',
    passwordsDoNotMatch: 'Las contraseñas no coinciden',
    accountCreatedSuccessfully: 'Cuenta creada exitosamente',
    loggedInSuccessfully: 'Sesión iniciada exitosamente',
    pleaseEnterYourEmailToReceiveYourPasswordResetLink:
      'Por favor ingrese su correo electrónico para recibir el enlace de restablecimiento de contraseña',
    passwordResetLinkSentToYourEmailIfItExistsInOurSystem:
      'El enlace de restablecimiento de contraseña se envió a su correo electrónico si existe en nuestro sistema',
    account: 'Cuenta',
    save: 'Guardar',
    nativeLanguage: 'Idioma Nativo',
    learningLanguage: 'Idioma de Aprendizaje',
    selectOne: 'Seleccione Uno',
    createdOn: 'Creado el',
    updatedOn: 'Actualizado el',
    deleteAccount: 'Eliminar Cuenta',
    logout: 'Cerrar Sesión',
    accountUpdatedSuccessfully: 'Cuenta actualizada exitosamente',
    errorUpdatingAccount: 'Error al actualizar la cuenta',
    deleteAccountConfirmation:
      'Eliminar su cuenta no se puede deshacer. Por favor escriba "ELIMINAR" para confirmar.',
    deleteAccountConfirmationSingle: 'ELIMINAR',
    deleteAccountConfirmationError: 'Entrada inválida, por favor escriba "ELIMINAR"',
    deleteAccountError: 'Error al eliminar la cuenta',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    logoutError: 'Error al cerrar sesión',
    native: 'Nativo',
    learning: 'Aprendizaje',
    searchCall: 'Buscar Llamada',
    cancelSearch: 'Cancelar Búsqueda',
    disconnect: 'Desconectar',
    callWillBeRecorded: 'La llamada será grabada',
    cancellingSearch: 'Cancelando búsqueda...',
    searchCancelled: 'Búsqueda cancelada',
    searchCancelError: 'Error al cancelar la búsqueda',
    searching: 'Buscando...',
    connecting: 'Conectando...',
    connected: 'Conectado',
    noMatchesFoundPleaseTryAgainLater:
      'No se encontraron coincidencias, por favor intente nuevamente más tarde',
    failedToConnect: 'Error al conectar',
    disconnected: 'Desconectado',
    failedToDisconnect: 'Error al desconectar',
    initializingClient: 'Inicializando cliente...',
    failedToInitializeClient: 'Error al inicializar el cliente',
    callRejected: 'Llamada rechazada',
    errorRejectingCall: 'Error al rechazar la llamada',
    callAccepted: 'Llamada aceptada',
    errorAcceptingCall: 'Error al aceptar la llamada',
    errorStartingCallRecording: 'Error al iniciar la grabación de la llamada',
    recordings: 'Grabaciones',
    adminViewOfAllRecordings: 'Vista de administrador de todas las grabaciones',
    yourBrowserDoesNotSupportTheAudioElement: 'Su navegador no soporta el elemento de audio',
    endOfList: 'Fin de la lista',
    resetPassword: 'Restablecer Contraseña',
    invalidPasswordResetLink: 'Enlace de restablecimiento de contraseña inválido',
    passwordResetSuccessfully: 'Restablecimiento de contraseña exitoso',
    passwordResetFailed: 'Error al restablecer la contraseña',
    yourSessionIsExpiringWouldYouLikeToContinue: 'Su sesión está por expirar, ¿desea continuar?',
    continueSession: 'Continuar Sesión',
    welcome: 'Bienvenido',
    continue: 'Continuar',
    failedToUpdateAccount: 'Error al actualizar la cuenta',
    errorRequiredField: 'Error - campo requerido',
    errorMinValueField: 'Error - valor mínimo',
    errorMaxValueField: 'Error - valor máximo',
    errorPhoneNumberField: 'Error - número de teléfono inválido',
    errorEmailField: 'Error - correo electrónico inválido',
    newVersionAvailable: 'Nueva versión disponible',
    reload: 'Recargar',
    errorLoadingCallerProfile: 'Error al cargar el perfil del llamante',
    icon: 'Icono',
    chooseIcon: 'Elegir Icono',
    mute: 'Silenciar',
    unmute: 'Activar Sonido',
    muteError: 'Error al silenciar',
    rateYourProficiency: 'Califique su nivel de dominio',
    pleaseRateYourProficiencyFor: 'Por favor califique su nivel de dominio para',
    submit: 'Enviar',
    errorLoadingMyMostRecentRating: 'Error al cargar mi calificación más reciente',
    callSummary: 'Resumen de Llamada',
    pleaseRateYourPartnersProficiencyFor: 'Por favor califique el nivel de dominio de su compañero para',
    pleaseRateTheCallQuality: 'Por favor califique la calidad de la llamada',
    errorSubmittingRating: 'Error al enviar la calificación',
    comments: 'Comentarios',
    callDuration: 'Duración de la Llamada',
    callHistory: 'Historial de Llamadas',
    errorLoadingLanguageTimes: 'Error al cargar los tiempos de idioma de los usuarios',
    languageCallTimes: 'Tiempos de Llamada de Idioma',
    selectAudioOutput: 'Seleccionar Salida de Audio',
    selectAudioOutputError: 'Error al seleccionar la salida de audio',
    selectAudioInput: 'Seleccionar Entrada de Audio',
    selectAudioInputError: 'Error al seleccionar la entrada de audio',
    audioPermissionRequired: 'Se requiere permiso de audio',
    noAudioElementFound: 'No se encontró el elemento de audio',
    bluetoothError: 'Error de Bluetooth',
    subscription: 'Suscripción',
    manageSubscription: 'Administrar Suscripción',
    inactive: 'Inactivo',
    active: 'Activo',
    unknown: 'Desconocido',
    monthly: 'Mensual',
    yearly: 'Anual',
    month: 'Mes',
    year: 'Año',
    unlimitedCalls: 'Llamadas ilimitadas',
    dayFreeTrial: 'día de prueba gratis',
    verifyEmail: 'Verificar Correo Electrónico',
    verifyPhone: 'Verificar Teléfono',
    sendEmail: 'Enviar Correo Electrónico',
    refresh: 'Refrescar',
    pleaseProvidePhoneForAccount: 'Por favor proporcione un número de teléfono para su cuenta',
    phoneNumber: 'Número de Teléfono',
    sendCode: 'Enviar Código',
    code: 'Código',
    verifyCode: 'Verificar Código',
    emailAlreadyVerifiedTitle: 'Correo Electrónico Ya Verificado',
    emailAlreadyVerifiedMessage: 'Su correo electrónico ya ha sido verificado',
    emailVerificationRequiredTitle: 'Verificación de Correo Electrónico Requerida',
    emailVerificationRequiredMessage: 'Se requiere verificación de correo electrónico para su cuenta',
    emailVerificationError: 'Error de verificación de correo electrónico',
    verificationSuccessfulTitle: 'Verificación Exitosa',
    verificationSuccessfulMessage: 'Redirigiendo...',
    verificationFailedTitle: 'Verificación Fallida',
    errorSendingEmail: 'Error al enviar el correo electrónico',
    verificationEmailSent: 'Correo electrónico de verificación enviado',
    codeSent: 'Código Enviado',
    errorSendingCode: 'Error al enviar el código',
    codeVerificationSuccessful: 'Verificación de código exitosa. Redirigiendo...',
    errorVerifyingCode: 'Error al verificar el código',
    waitlist: 'Lista de Espera',
    enterYourEmailToJoinTheWaitlist: 'Ingrese su correo electrónico para unirse a la lista de espera',
    youHaveJoinedTheWaitlistSuccessfully: 'Se ha unido a la lista de espera con éxito',
    errorJoiningWaitlist: 'Error al unirse a la lista de espera',
    platform: 'Plataforma',

    errorLoadingRecordings: 'Error al cargar las grabaciones',
    noRecordingsFound: 'No se encontraron grabaciones',
    errorDownloadingReport: 'Error al descargar el informe',
    errorLoadingWaitlist: 'Error al cargar la lista de espera',
    waitlistEntries: 'Entradas de Lista de Espera',
    ios: 'iOS',
    android: 'Android',
    web: 'Web',
    reportUser: 'Reportar Usuario',
    none: 'Ninguno',
    explicitLanguage: 'Lenguaje explícito',
    sexualContent: 'Contenido sexual',
    harassment: 'Acoso',
    robot: 'Robot',
    other: 'Otro',
    pleaseLoginToContinue: 'Por favor inicie sesión para continuar',
    home: 'Inicio',
    notificationSettings: 'Configuración de Notificaciones',
    youHaveOptedOutFromEmails: 'Se ha dado de baja de los correos electrónicos',
    change: 'Cambio',
    newCallSearch: 'Nueva Búsqueda de Llamadas',
    push: 'Push',
    sms: 'SMS',
    transactionReceipt: 'Transacción Recibo',
    unusualLogin: 'Inicio de Sesión Inusual',
    errorLoadingNotificationSettings: 'Error al cargar la configuración de notificaciones',
    notificationSettingsSavedSuccessfully: 'Configuración de notificaciones guardada exitosamente',
    errorSavingNotificationSettings: 'Error al guardar la configuración de notificaciones',
    accountEmailPreference: 'Preferencia de Correo Electrónico de la Cuenta',
    manageYourAccountEmailPreferences: 'Administre sus preferencias de correo electrónico de la cuenta',
    unsubscribe: 'Darse de baja',
    resubscribe: 'Volver a suscribirse',
    successfullyChangedEmailPreferences: 'Preferencias de correo electrónico cambiadas con éxito',
    errorChangingEmailPreferences: 'Error al cambiar las preferencias de correo electrónico',
    enterCommentsHere: 'Ingrese comentarios aquí',
    input: 'Entrada',
    output: 'Salida',
    uploadImage: 'Subir Imagen',
    removeImage: 'Eliminar Imagen',
    image: 'Imagen',
    saving: 'Salvación',
    selectImage: 'Seleccionar imagen',
    prompt: 'Indicación',
    prompts: 'Indicaciones',
    defaultPrompts: 'Indicaciones Predeterminadas',
    errorSavingPrompts: 'Error al guardar las indicaciones',
    errorSavingDefaultPrompts: 'Error al guardar las indicaciones predeterminadas',
    enabled: 'Habilitado',
    groupName: 'Nombre del Grupo',
    enterGroupName: 'Ingrese el nombre del grupo',
    enterConversationPrompt: 'Ingrese la indicación de conversación',
    promptsSavedSuccessfully: 'Indicaciones guardadas exitosamente',
    defaultPromptsSavedSuccessfully: 'Indicaciones predeterminadas guardadas exitosamente',
    next: 'Siguiente',
    yourTurnToPrompt: 'Tu turno para indicar',
    theirTurnToPrompt: 'Su turno para indicar',
    confirmDelete: 'Confirmar Eliminación',
    areYouSureYouWantToDeleteThisPromptList:
      '¿Estás seguro de que quieres eliminar esta lista de indicaciones?',
    delete: 'Eliminar',
    areYouSureYouWantToDeleteThisPrompt: '¿Estás seguro de que quieres eliminar esta indicación?',
    frequency: 'Frecuencia',
    xAtATime: 'X a la vez',
    enterFrequency: 'Ingrese la frecuencia',
    promptListCreated: 'Lista de indicaciones creada',
    errorUpdatingPromptMetadata: 'Error al actualizar los metadatos de la indicación',
    paymentMethod: 'Método de Pago',
    cardOnFile: 'Tarjeta en Archivo',
    updatePaymentMethod: 'Actualizar Método de Pago',
    nameOnCard: 'Nombre en la Tarjeta',
    noPaymentMethod: 'Sin Método de Pago',
    pleaseAddAPaymentMethod: 'Por favor agregue un método de pago',
    errorLoadingPaymentMethods: 'Error al cargar los métodos de pago',
    paymentMethodUpdatedSuccessfully: 'Método de pago actualizado exitosamente',
    errorUpdatingPaymentMethod: 'Error al actualizar el método de pago',
    downgrade: 'Degradar',
    subscribe: 'Suscribirse',
    free: 'Gratis',
    currentSubscription: 'Suscripción Actual',
    oneFifteenMinuteCallPerDay: 'una 15 minutos llamada por día',
    errorUpdatingSubscription: 'Error al actualizar la suscripción',
    errorGettingPrices: 'Error al obtener precios',
    periodEndsAt: 'El período termina en',
    subscriptionUpdatedSuccessfully: 'Suscripción actualizada exitosamente',
    transactions: 'Transacciones',
    viewTransactions: 'Ver Transacciones',
    errorLoadingTransactions: 'Error al cargar las transacciones',
    paymentSucceeded: 'Pago Exitoso',
    paymentFailed: 'Pago Fallido',
    paymentRefunded: 'Pago Reembolsado',
    paidUntil: 'Pagado Hasta',
    subscribeToCreatePrompts: 'Suscríbase para crear indicaciones',
    neitherUserSubscribedPromptsUnavailable: 'Ningún usuario suscrito, indicaciones no disponibles',
    mobileApp: 'Aplicación Móvil',
    mobileAppDescription:
      'JuntoTalk ofrece soporte para aplicaciones web progresivas. Puede agregar app.juntotalk.com a su pantalla de inicio y usarlo como una aplicación nativa.',
    mobileAppIOSInstructions:
      'Para hacer esto en iOS, toque el botón de compartir, luego toque "Agregar a la pantalla de inicio".',
    mobileAppAndroidInstructions:
      'Para hacer esto en Android, toque el botón de menú en Chrome, luego toque "Agregar a la pantalla de inicio".',
    subscribeToAccessCallRecordings: 'Suscríbase para acceder a las grabaciones de llamadas',
    callRecordings: 'Grabaciones de Llamadas',
    website: 'Sitio Web',
    otherLearningLanguage: 'Otro Idioma de Aprendizaje',
    otherNativeLanguage: 'Otro Idioma Nativo',
    acceptCallsFrom: 'Aceptar Llamadas de',
    selectOneOrMore: 'Seleccione Uno o Más',
    acceptCallsFromMultipleLanguages: 'Aceptar llamadas de varios idiomas',
    moreInfo: 'Más Información',
    accounts: 'Cuentas',
    noAccountFound: 'No se encontró cuenta',
    noAccountsFound: 'No se encontraron cuentas',
    go: 'Ir',
    freeUntil: 'Gratis hasta',
    done: 'Hecho',
    errorLoadingAccount: 'Error al cargar la cuenta',
    errorLoadingAccounts: 'Error al cargar las cuentas',
    search: 'Buscar',
    ai: 'IA',
    failedToChatWithAi: 'Error al chatear con la IA',
    callWillBeAnsweredByAI: 'Si no hay nadie disponible, la llamada será contestada por la IA',
    hasPictures: 'Tiene Imágenes',
    errorRemovingImage: 'Error al eliminar la imagen',
    viewImage: 'Ver Imagen',
    errorSendingMessage: 'Error al enviar el mensaje',
    pleaseOnlyUploadPngOrJpgFiles: 'Por favor, solo suba archivos PNG o JPG',
  },
  pt: {
    en: 'Inglês',
    es: 'Espanhol',
    pt: 'Português',
    fr: 'Francês',
    de: 'Alemão',
    ja: 'Japonês',
    it: 'Italiano',
    ko: 'Coreano',
    zh: 'Chinês',
    hi: 'Hindi',
    ru: 'Russo',
    ar: 'Árabe',
    email: 'E-mail',
    displayName: 'Nome de Usuário',
    password: 'Senha',
    confirmPassword: 'Confirmar Senha',
    acceptTerms: 'Eu aceito os termos e condições',
    iAccept: 'Eu aceito',
    terms: 'termos',
    privacy: 'privacidade',
    copyright: 'direito autoral',
    createAccount: 'Criar Conta',
    login: 'Entrar',
    returnToLogin: 'Voltar para Entrar',
    forgotPassword: 'Esqueci minha Senha',
    passwordsDoNotMatch: 'As senhas não coincidem',
    accountCreatedSuccessfully: 'Conta criada com sucesso',
    loggedInSuccessfully: 'Sessão iniciada com sucesso',
    pleaseEnterYourEmailToReceiveYourPasswordResetLink:
      'Por favor, digite seu e-mail para receber o link de redefinição de senha',
    passwordResetLinkSentToYourEmailIfItExistsInOurSystem:
      'O link de redefinição de senha foi enviado para o seu e-mail, se ele existir em nosso sistema',
    account: 'Conta',
    save: 'Salvar',
    nativeLanguage: 'Idioma Nativo',
    learningLanguage: 'Idioma de Aprendizagem',
    selectOne: 'Selecione Um',
    createdOn: 'Criado em',
    updatedOn: 'Atualizado em',
    deleteAccount: 'Excluir Conta',
    logout: 'Sair',
    accountUpdatedSuccessfully: 'Conta atualizada com sucesso',
    errorUpdatingAccount: 'Erro ao atualizar a conta',
    deleteAccountConfirmation:
      'Excluir sua conta não pode ser desfeito. Por favor, digite "EXCLUIR" para confirmar.',
    deleteAccountConfirmationSingle: 'EXCLUIR',
    deleteAccountConfirmationError: 'Entrada inválida, por favor digite "EXCLUIR"',
    deleteAccountError: 'Erro ao excluir a conta',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    logoutError: 'Erro ao sair',
    native: 'Nativo',
    learning: 'Aprendizagem',
    searchCall: 'Pesquisar Chamada',
    cancelSearch: 'Cancelar Pesquisa',
    disconnect: 'Desconectar',
    callWillBeRecorded: 'A chamada será gravada',
    cancellingSearch: 'Cancelando pesquisa...',
    searchCancelled: 'Pesquisa cancelada',
    searchCancelError: 'Erro ao cancelar a pesquisa',
    searching: 'Pesquisando...',
    connecting: 'Conectando...',
    connected: 'Conectado',
    noMatchesFoundPleaseTryAgainLater:
      'Nenhuma correspondência encontrada, por favor tente novamente mais tarde',
    failedToConnect: 'Erro ao conectar',
    disconnected: 'Desconectado',
    failedToDisconnect: 'Erro ao desconectar',
    initializingClient: 'Inicializando cliente...',
    failedToInitializeClient: 'Erro ao inicializar o cliente',
    callRejected: 'Chamada rejeitada',
    errorRejectingCall: 'Erro ao rejeitar a chamada',
    callAccepted: 'Chamada aceita',
    errorAcceptingCall: 'Erro ao aceitar a chamada',
    errorStartingCallRecording: 'Erro ao iniciar a gravação da chamada',
    recordings: 'Gravações',
    adminViewOfAllRecordings: 'Visualização de administrador de todas as gravações',
    yourBrowserDoesNotSupportTheAudioElement: 'Seu navegador não suporta o elemento de áudio',
    endOfList: 'Fim da lista',
    resetPassword: 'Redefinir Senha',
    invalidPasswordResetLink: 'Link de redefinição de senha inválido',
    passwordResetSuccessfully: 'Senha redefinida com sucesso',
    passwordResetFailed: 'Erro ao redefinir a senha',
    yourSessionIsExpiringWouldYouLikeToContinue: 'Sua sessão está expirando, você gostaria de continuar?',
    continueSession: 'Continuar Sessão',
    welcome: 'Bem-vindo',
    continue: 'Continuar',
    failedToUpdateAccount: 'Erro ao atualizar a conta',
    errorRequiredField: 'Erro - campo obrigatório',
    errorMinValueField: 'Erro - valor mínimo',
    errorMaxValueField: 'Erro - valor máximo',
    errorPhoneNumberField: 'Erro - número de telefone inválido',
    errorEmailField: 'Erro - e-mail inválido',
    newVersionAvailable: 'Nova versão disponível',
    reload: 'Recarregar',
    errorLoadingCallerProfile: 'Erro ao carregar o perfil do chamador',
    icon: 'Ícone',
    chooseIcon: 'Escolher Ícone',
    mute: 'Silenciar',
    unmute: 'Ativar Som',
    muteError: 'Erro ao silenciar',
    rateYourProficiency: 'Avalie seu nível de domínio',
    pleaseRateYourProficiencyFor: 'Por favor avalie seu nível de domínio para',
    submit: 'Enviar',
    errorLoadingMyMostRecentRating: 'Erro ao carregar minha classificação mais recente',
    callSummary: 'Resumo da Chamada',
    pleaseRateYourPartnersProficiencyFor: 'Por favor avalie o nível de domínio do seu parceiro para',
    pleaseRateTheCallQuality: 'Por favor avalie a qualidade da chamada',
    errorSubmittingRating: 'Erro ao enviar a classificação',
    comments: 'Comentários',
    callDuration: 'Duração da Chamada',
    callHistory: 'Histórico de Chamadas',
    errorLoadingLanguageTimes: 'Erro ao carregar os tempos de idioma dos usuários',
    languageCallTimes: 'Tempos de Chamada de Idioma',
    selectAudioOutput: 'Selecionar Saída de Áudio',
    selectAudioOutputError: 'Erro ao selecionar a saída de áudio',
    selectAudioInput: 'Selecionar Entrada de Áudio',
    selectAudioInputError: 'Erro ao selecionar a entrada de áudio',
    audioPermissionRequired: 'Permissão de áudio necessária',
    noAudioElementFound: 'Nenhum elemento de áudio encontrado',
    bluetoothError: 'Erro de Bluetooth',
    subscription: 'Assinatura',
    manageSubscription: 'Gerenciar Assinatura',
    inactive: 'Inativo',
    active: 'Ativo',
    unknown: 'Desconhecido',
    monthly: 'Mensal',
    yearly: 'Anual',
    month: 'Mês',
    year: 'Ano',
    unlimitedCalls: 'Chamadas ilimitadas',
    dayFreeTrial: 'dias de teste grátis',
    verifyEmail: 'Verificar E-mail',
    verifyPhone: 'Verificar Telefone',
    sendEmail: 'Enviar E-mail',
    refresh: 'Atualizar',
    pleaseProvidePhoneForAccount: 'Por favor, forneça um número de telefone para sua conta',
    phoneNumber: 'Número de Telefone',
    sendCode: 'Enviar Código',
    code: 'Código',
    verifyCode: 'Verificar Código',
    emailAlreadyVerifiedTitle: 'E-mail Já Verificado',
    emailAlreadyVerifiedMessage: 'Seu e-mail já foi verificado',
    emailVerificationRequiredTitle: 'Verificação de E-mail Necessária',
    emailVerificationRequiredMessage: 'A verificação de e-mail é necessária para sua conta',
    emailVerificationError: 'Erro de verificação de e-mail',
    verificationSuccessfulTitle: 'Verificação Bem Sucedida',
    verificationSuccessfulMessage: 'Redirecionando...',
    verificationFailedTitle: 'Verificação Falhou',
    errorSendingEmail: 'Erro ao enviar e-mail',
    verificationEmailSent: 'E-mail de verificação enviado',
    codeSent: 'Código Enviado',
    errorSendingCode: 'Erro ao enviar código',
    codeVerificationSuccessful: 'Verificação de código bem sucedida. Redirecionando...',
    errorVerifyingCode: 'Erro ao verificar código',
    waitlist: 'Lista de Espera',
    enterYourEmailToJoinTheWaitlist: 'Insira seu e-mail para entrar na lista de espera',
    youHaveJoinedTheWaitlistSuccessfully: 'Você entrou na lista de espera com sucesso',
    errorJoiningWaitlist: 'Erro ao entrar na lista de espera',
    platform: 'Plataforma',
    errorLoadingRecordings: 'Erro ao carregar gravações',
    noRecordingsFound: 'Nenhuma gravação encontrada',
    errorDownloadingReport: 'Erro ao baixar o relatório',
    errorLoadingWaitlist: 'Erro ao carregar a lista de espera',
    waitlistEntries: 'Entradas da Lista de Espera',
    ios: 'iOS',
    android: 'Android',
    web: 'Web',
    reportUser: 'Reportar Usuário',
    none: 'Nenhum',
    explicitLanguage: 'Linguagem explícita',
    sexualContent: 'Conteúdo sexual',
    harassment: 'Assédio',
    robot: 'Robô',
    other: 'Outro',
    pleaseLoginToContinue: 'Por favor, faça login para continuar',
    home: 'Início',
    notificationSettings: 'Configurações de Notificação',
    youHaveOptedOutFromEmails: 'Você optou por não receber e-mails',
    change: 'Mudança',
    newCallSearch: 'Nova Pesquisa de Chamadas',
    push: 'Push',
    sms: 'SMS',
    transactionReceipt: 'Transação Recibo',
    unusualLogin: 'Login Incomum',
    errorLoadingNotificationSettings: 'Erro ao carregar as configurações de notificação',
    notificationSettingsSavedSuccessfully: 'Configurações de notificação salvas com sucesso',
    errorSavingNotificationSettings: 'Erro ao salvar as configurações de notificação',
    accountEmailPreference: 'Preferência de E-mail da Conta',
    manageYourAccountEmailPreferences: 'Gerencie suas preferências de e-mail da conta',
    unsubscribe: 'Cancelar inscrição',
    resubscribe: 'Inscrever-se novamente',
    successfullyChangedEmailPreferences: 'Preferências de e-mail alteradas com sucesso',
    errorChangingEmailPreferences: 'Erro ao alterar as preferências de e-mail',
    enterCommentsHere: 'Insira comentários aqui',
    input: 'Entrada',
    output: 'Saída',
    uploadImage: 'Enviar Imagem',
    removeImage: 'Remover Imagem',
    image: 'Imagem',
    saving: 'Salvando',
    selectImage: 'Selecione a imagem',
    prompt: 'Indicação',
    prompts: 'Indicações',
    defaultPrompts: 'Indicações Padrão',
    errorSavingPrompts: 'Erro ao salvar as indicações',
    errorSavingDefaultPrompts: 'Erro ao salvar as indicações padrão',
    enabled: 'Habilitado',
    groupName: 'Nome do Grupo',
    enterGroupName: 'Digite o nome do grupo',
    enterConversationPrompt: 'Digite a indicação da conversa',
    promptsSavedSuccessfully: 'Indicações salvas com sucesso',
    defaultPromptsSavedSuccessfully: 'Indicações padrão salvas com sucesso',
    next: 'Próximo',
    yourTurnToPrompt: 'Sua vez de indicar',
    theirTurnToPrompt: 'A vez deles de indicar',
    confirmDelete: 'Confirmar Exclusão',
    areYouSureYouWantToDeleteThisPromptList: 'Tem certeza de que deseja excluir esta lista de indicações?',
    delete: 'Excluir',
    areYouSureYouWantToDeleteThisPrompt: 'Tem certeza de que deseja excluir esta indicação?',
    frequency: 'Frequência',
    xAtATime: 'X de cada vez',
    enterFrequency: 'Digite a frequência',
    promptListCreated: 'Lista de indicações criada',
    errorUpdatingPromptMetadata: 'Erro ao atualizar os metadados da indicação',
    paymentMethod: 'Método de Pagamento',
    cardOnFile: 'Cartão em Arquivo',
    updatePaymentMethod: 'Atualizar Método de Pagamento',
    nameOnCard: 'Nome no Cartão',
    noPaymentMethod: 'Sem Método de Pagamento',
    pleaseAddAPaymentMethod: 'Por favor, adicione um método de pagamento',
    errorLoadingPaymentMethods: 'Erro ao carregar os métodos de pagamento',
    paymentMethodUpdatedSuccessfully: 'Método de pagamento atualizado com sucesso',
    errorUpdatingPaymentMethod: 'Erro ao atualizar o método de pagamento',
    downgrade: 'Rebaixar',
    subscribe: 'Inscrever-se',
    free: 'Grátis',
    currentSubscription: 'Assinatura Atual',
    oneFifteenMinuteCallPerDay: 'uma chamada de 15 minutos por dia',
    errorUpdatingSubscription: 'Erro ao atualizar a assinatura',
    errorGettingPrices: 'Erro ao obter preços',
    periodEndsAt: 'O período termina em',
    subscriptionUpdatedSuccessfully: 'Assinatura atualizada com sucesso',
    transactions: 'Transações',
    viewTransactions: 'Ver Transações',
    errorLoadingTransactions: 'Erro ao carregar as transações',
    paymentSucceeded: 'Pagamento Efetuado',
    paymentFailed: 'Pagamento Falhou',
    paymentRefunded: 'Pagamento Reembolsado',
    paidUntil: 'Pago Até',
    subscribeToCreatePrompts: 'Inscreva-se para criar indicações',
    neitherUserSubscribedPromptsUnavailable: 'Nenhum usuário inscrito, indicações indisponíveis',
    mobileApp: 'Aplicativo Móvel',
    mobileAppDescription:
      'JuntoTalk oferece suporte a aplicativos da web progressivos. Você pode adicionar app.juntotalk.com à sua tela inicial e usá-lo como um aplicativo nativo.',
    mobileAppIOSInstructions:
      'Para fazer isso no iOS, toque no botão de compartilhamento e depois toque em "Adicionar à tela inicial".',
    mobileAppAndroidInstructions:
      'Para fazer isso no Android, toque no botão de menu no Chrome e depois toque em "Adicionar à tela inicial".',
    subscribeToAccessCallRecordings: 'Inscreva-se para acessar as gravações de chamadas',
    callRecordings: 'Gravações de Chamadas',
    website: 'Site',
    otherLearningLanguage: 'Outro Idioma de Aprendizagem',
    otherNativeLanguage: 'Outro Idioma Nativo',
    acceptCallsFrom: 'Aceitar Chamadas de',
    selectOneOrMore: 'Selecione Um ou Mais',
    acceptCallsFromMultipleLanguages: 'Aceitar chamadas de vários idiomas',
    moreInfo: 'Mais Informações',
    accounts: 'Contas',
    noAccountFound: 'Nenhuma conta encontrada',
    noAccountsFound: 'Nenhuma conta encontrada',
    go: 'Ir',
    freeUntil: 'Grátis até',
    done: 'Feito',
    errorLoadingAccount: 'Erro ao carregar a conta',
    errorLoadingAccounts: 'Erro ao carregar as contas',
    search: 'Pesquisar',
    ai: 'IA',
    failedToChatWithAi: 'Falha ao conversar com a IA',
    callWillBeAnsweredByAI: 'Se ningupem estiver disponível, a chamada será atendida pela IA',
    hasPictures: 'Tem Imagens',
    errorRemovingImage: 'Erro ao remover a imagem',
    viewImage: 'Ver Imagem',
    errorSendingMessage: 'Erro ao enviar a mensagem',
    pleaseOnlyUploadPngOrJpgFiles: 'Por favor, envie apenas arquivos PNG ou JPG',
  },
};
