import { Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { ErrorService } from '../services/error.service';
import { GlobalConfigService } from '../services/global-config.service';
import { UserActivityService } from '../services/user-activity.service';
import { TranslationService } from '../services/translation.service';
import { SafeArea } from 'capacitor-plugin-safe-area';

export function appInitializer(
  accountService: AccountService,
  router: Router,
  globalConfigService: GlobalConfigService,
  userActivityService: UserActivityService,
  errorService: ErrorService,
  tls: TranslationService
) {
  return async () => {
    // safe area bug fix
    SafeArea.getSafeAreaInsets().then(data => {
      const { insets } = data;
      document.body.style.setProperty('--ion-safe-area-top', `${insets.top}px`);
      document.body.style.setProperty('--ion-safe-area-right', `${insets.right}px`);
      document.body.style.setProperty('--ion-safe-area-bottom', `${insets.bottom}px`);
      document.body.style.setProperty('--ion-safe-area-left', `${insets.left}px`);
    });

    // change language
    accountService.account$.subscribe(account => {
      if (account) {
        tls.setLanguage(account.nativeLanguage);
      }
    });

    // attempt to refresh token on app start up to auto authenticate
    const promises = [];
    promises.push(
      accountService.refreshToken().catch(async err => {
        console.error(`Error refreshing token`, err);
        const path = location.pathname;
        const noAuthPaths = [
          '/terms',
          '/privacy',
          '/copyright',
          '/verify-email',
          '/reset-password',
          '/refer',
          '/account-email-preference',
          '/waitlist',
        ];
        const startsWithNoAuthPath = noAuthPaths.some(p => path.startsWith(p));
        console.log('startsWithNoAuthPath', startsWithNoAuthPath, path);
        if (!startsWithNoAuthPath) {
          const redirectPath =
            path.startsWith('/login') || path.length <= 1
              ? path
              : `/login?redirect=${encodeURIComponent(path)}`;
          await router.navigateByUrl(redirectPath);
        }
      })
    );
    promises.push(
      globalConfigService.refresh().catch(async err => {
        console.error(`Error getting global config`, err);
      })
    );
    promises.push(userActivityService.init());

    try {
      await Promise.all(promises);
    } catch (err) {
      await errorService.displayError(`App initializer failed`, err, true);
    }
  };
}
